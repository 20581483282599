<template>
  <form id="form_add" @submit.prevent="onSubmit" class="p-field p-4">
    <div class="flex">
      <div class="p-fluid col-2">
        <label>Code</label>
        <InputText
          type="text"
          disabled
          v-model="uniqueId"
          placeholder="Généré automatiquement"
        />
      </div>
      <div class="p-fluid col-2">
        <label>Catégorie</label>
        <Dropdown
          :options="categories"
          optionLabel="name"
          optionValue="id"
          v-model="category"
          placeholder="Selectionnez"
          :filter="true"
        />
      </div>
      <div class="p-fluid col-6">
        <label>Nom</label>
        <InputText type="text" v-model="name" />
      </div>
      <div class="p-fluid col-1">
        <label>Cond.</label>
        <InputText
          type="number"
          v-model="capacity"
          step="0.01"
          placeholder="(L)"
        />
      </div>
      <div class="p-fluid col-1">
        <label>Livraison</label>
        <InputText type="number" v-model="cond" />
      </div>
    </div>
    <div class="flex justify-content-end mt-3">
      <Button
        class="p-button-secondary p-button-text mr-2"
        label="Effacer"
        icon="pi pi-times"
        @click="onClear"
      ></Button>
      <Button
        class="p-button-success mr-2"
        label="Ajouter"
        icon="pi pi-plus"
        type="submit"
        form="form_add"
        :disabled="!name || !cond || !capacity || !category"
      ></Button>
    </div>
  </form>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { padStart } from "lodash";
import { projectDatabase, serverValue } from "../../firebase/config";
import { useToast } from "primevue/usetoast";

export default {
  props: {
    docs: [Object, Array],
  },
  setup(props, { emit }) {
    const toast = useToast();

    const newArticle = reactive({
      category: undefined,
      name: undefined,
      capacity: undefined,
      cond: undefined,
    });

    const onClear = () => {
      newArticle.category = undefined;
      newArticle.name = undefined;
      newArticle.capacity = undefined;
      newArticle.cond = undefined;
    };

    const categories = computed(() => {
      let array = [];
      Object.entries(props.docs.categories).forEach((category) => {
        array.push({ id: category[0], name: category[1].name });
      });
      return array;
    });

    const uniqueId = computed(() => {
      if (newArticle.category) {
        return `01${newArticle.category}${padStart(
          props.docs.categories[newArticle.category]._counters + 1,
          3,
          "0"
        )}`;
      }
      return "";
    });

    const onSubmit = async () => {
      try {
        const updates = {};
        updates[`mercurial/articles/${uniqueId.value}`] = newArticle;
        // const countersRef = `mercurial/categories/${newArticle.category}/_counters`;
        updates[`mercurial/categories/${newArticle.category}/_counters`] =
          serverValue.increment(1);
        // const res = await projectDatabase.ref(countersRef).get();
        // const counter = res.val();
        await projectDatabase.ref().update(updates);
        onClear();
        toast.add({
          severity: "success",
          detail: "Nouveau produit ajouté !",
          life: 2500,
        });
        emit("update");
      } catch (e) {
        console.log(e);
      }
    };

    return { ...toRefs(newArticle), uniqueId, categories, onSubmit, onClear };
  },
};
</script>

<style lang="scss">
.p-card.p-component.footer-end > .p-card-body > .p-card-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem;
}
</style>
