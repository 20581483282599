<template>
  <BasePending v-if="pending"></BasePending>
  <Accordion v-else :activeIndex="[1]" :multiple="true">
    <AccordionTab header="Ajouter un produit">
      <CreateArticle :docs="docs" @update="fetchMercurial"></CreateArticle>
    </AccordionTab>
    <AccordionTab header="Mercuriale">
      <MercurialDataTable
        :docs="docs"
        :articles-array="articlesArray"
        :pending="pending"
        :update-one="updateOne"
        :rates-columns="ratesColumns"
      />
    </AccordionTab>
  </Accordion>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import useDatabase from "../../composables/useDatabase";
import MercurialDataTable from "./_MercurialDataTable";
import CreateArticle from "./_Create";
import { projectDatabase } from "@/firebase/config";

export default {
  components: { CreateArticle, MercurialDataTable },
  setup() {
    const { getAll, updateOne } = useDatabase("mercurial/articles");
    const { docs: articlesArray, pending: articlesPending } = getAll();

    const ratesColumns = ref([]);

    const docs = ref([]);
    const pending = ref(true);

    const fetchMercurial = async () => {
      ratesColumns.value = [];
      const res = await projectDatabase.ref("mercurial").get();
      docs.value = res.val();
      Object.entries(docs.value.articles).map((article) => {
        article.rates = {};
      });
      Object.entries(docs.value.rates).forEach((rate) => {
        ratesColumns.value.push({
          field: rate[0],
          header: rate[1].name,
        });
      });
      pending.value = false;
    };

    onMounted(async () => {
      await fetchMercurial();
    });

    watch(articlesArray, () => {
      if (!articlesPending.value && !pending.value) {
        articlesArray.value.forEach((a) => {
          if (!a.rates) {
            a["rates"] = {};
          }
        });
      }
    });

    return {
      pending,
      articlesArray,
      docs,
      updateOne,
      ratesColumns,
      fetchMercurial,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-accordion-tab) {
  .p-accordion-content {
    padding: 0;
  }
}
</style>
